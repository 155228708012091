import React from 'react'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import './Hero.css'

import BgVideo from '../../assets/video_bg.mp4'


const Hero = () => {
    return (
<>        

        <div className='hero'>
            <video src={BgVideo} autoPlay muted loop playsInline className='video-bg' />
            <div className="hero-container">

                <div className="content">
                        <h1>BEACH STREET REHAB</h1>
                        <h2>A great life starts with a strong mind and a strong body.</h2>
                    <Fade left>
                        <h3>Substance Abuse Addiction and Mental Health Treatment Facility in Huntington Beach</h3>
                    </Fade>
                        <br />
                        <div className='hero-btn'>
                        <Link to="/contact">
                        <button>Contact Us</button>
                    </Link>
                    </div>

                </div>
            </div>
        </div>


</>

    )
}

export default Hero